import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ConstantsService } from './constants.service';
import { HttpServiceService } from './http-service.service';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from "@angular/common"; 
import { query } from '@angular/animations';
import * as moment from "moment"
import { BehaviorSubject, Subject } from 'rxjs';
import { WebSocketService } from './web-socket.service';
import { Router } from '@angular/router';

@Injectable()
export class CommonService {
    docTypeList: any[];
    cityListFilter: any;
    categoryTreatmentMap: any;
    categoryListFilter: any;
    isHrOps=localStorage.getItem("roleHROPS") ? true : false;
    public wsMessages: Subject<any>;
    wsMessagesObservable: Subject<any> = new Subject();
    roleSeoContent = localStorage.getItem("roleMedOpsMarketing") ? true : false;
    emrTypeList = [
        { category: 'Aesthetics', type: 'web' },
        { category: 'Ayurveda', type: 'web' },
        { category: 'BPH', type: 'native' },
        { category: 'Breast', type: 'web' },
        { category: 'Cosmetic Gynaecology', type: 'web' },
        { category: 'Covid-19', type: 'web' },
        { category: 'Dental', type: 'web' },
        { category: 'Dermatology', type: 'web' },
        { category: 'Diagnostic', type: 'web' },
        { category: 'ENT', type: 'native' },
        { category: 'General', type: 'web' },
        { category: 'General Gynaecology', type: 'web' },
        { category: 'General Physician', type: 'web' },
        { category: 'Gynaecology', type: 'web' },
        { category: 'Gynaecology MTP', type: 'web' },
        { category: 'HairTransplant', type: 'web' },
        { category: 'IVF', type: 'web' },
        { category: 'Kidney Stone', type: 'native' },
        { category: 'Laparoscopy', type: 'web' },
        { category: 'Lasik', type: 'native' },
        { category: 'Online Consulting', type: 'web' },
        { category: 'Ophthalmology', type: 'native' },
        { category: 'Orthopaedics', type: 'native' },
        { category: 'Pilonidal Sinus', type: 'web' },
        { category: 'Proctology', type: 'web' },
        { category: 'Surgical Gynaecology', type: 'web' },
        { category: 'Urology', type: 'web' },
        { category: 'Urology 2', type: 'web' },
        { category: 'Vascular', type: 'web' },
        { category: 'Weight Loss', type: 'native' },
      ];

    constructor(private datePipe: DatePipe,
        private http: HttpServiceService,
        private httpClient: HttpClient,
        private constants: ConstantsService,
        private toastr: ToastrService,
        private wsService: WebSocketService,
        private router:Router) { 

        }

    /**
     *this method posts data to a server
     *@param url - the url to post data to
     *@param data - the data to send to the url
     */

    brandColors = {
        'red': '#de6b59',
        'green': '#14c6aa',
        'yellow': '#ffba4b',
        'blue': 'rgb(43, 136, 217)'
    };
    degreeArr = [];
    disposition = [];
    subDisposition = [];
    otherRoles = []
    userId = "";
    activeInactiveReason = [];
    private booleanSubject = new BehaviorSubject<boolean>(false);
  boolean$ = this.booleanSubject.asObservable();

    addDays(myDate, days) {
        return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    addMinutes(myTime, minute) {
        return new Date(myTime.getTime() + minute * 60 * 1000);
    }

    removeDays(myDate, days) {
        return new Date(myDate.getTime() - days * 24 * 60 * 60 * 1000);
    }

    sortArrayOfObjectByDate(array, key) {
        return array
            .filter(Boolean)
            .sort(
                (a, b) =>
                    new Date(b[key]).valueOf() - new Date(a[key]).valueOf()
            );
    }

    returnFormatedTimestampForTable(timestamp, format) {
        return moment.utc(timestamp).format(format);
    }

    formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    formatTime(date) {
        var d = new Date(date),
            hour = "" + (d.getHours()),
            minute = "" + (d.getMinutes()),
            seconds = "" + (d.getSeconds());
        if (hour.length < 2) hour = "0" + hour;
        if (minute.length < 2) minute = "0" + minute;
        return [hour, minute].join(":");
    }
    updateBoolean(newValue: boolean) {
        this.booleanSubject.next(newValue);
      }
    makeDateFormat(value) {
        return this.datePipe.transform(value, "yyyy-MM-dd");
    }
    async updateDocName(reqObj){
        return await this.http.putData(this.constants.updateDocName,reqObj)
    }
  
    sendEmail(obj) {
        // attachment = attachment.map(a => {
        //     return a.substr(a.indexOf('AR'), a.length).replace(/%3A/g, ':');
        // });
        // let reqObj = [
        //     {
        //         to: to,
        //         msg: {
        //             subject: subject,
        //             body: {
        //                 type: 'text/html',
        //                 content: bodyContent
        //             }
        //         }
        //     }
        // ];
        // if (attachment.length > 0) {
        //     reqObj[0].msg['attachment'] = attachment;
        // }
        // console.log(JSON.stringify(reqObj));
        this.http
            .postData(this.constants.sendEmailDirect, obj)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            // this.toastr.error('Only digits inputs are allowed', 'Invalid');
            return false;
        }
        return true;
    }

    getCityList() {
        let url = this.constants.getCityList
        this.http.postData(url, {}).then(response => {
            if (response['status'] && response['status'].match(/Success/gi) && response['result'].length > 0) {
                this.cityListFilter = response['result'] ? response['result'] : []
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    getCategoryList() {
        this.categoryListFilter = []
        let url = this.constants.getCategoryAndTreatments
        this.http.postData(url, {}).then(response => {
            if (response['status'] && response['status'].match(/Success/gi) && response['result'].length > 0) {
                this.categoryTreatmentMap = response['result']
                response['result'].map((item) => {
                    this.categoryListFilter.push(item['Disease'])
                })
            }
        })
    }

    async fetchCommonData() {
        let output: any;
        let queryObj: any = {
            "Name": [
                "DocsDegreeData"
            ]
        }
        try {
            output = await this.http.getD(this.constants.getcommondata + '?Name=DocsDegreeData')
            if (output['status'] == "Success" && output['result']) {
                this.degreeArr = output['result']['data'] ? output['result']['data'] : [];
            }
            // console.log("insurance company data", this.insuranceCompanyData)
        } catch (error) {
            this.toastr.error(error, "Failed !");
        }
    }

    async fetchActiveInactiveReasonData() {
        let output: any;
        try {
            output = await this.http.getD(this.constants.getcommondata + '?Name=Active_Inactive_Reason')
            if (output['status'] == "Success" && output['result']) {
                this.activeInactiveReason = output['result']['dataObject'] ? output['result']['dataObject'] : [];
            }
        } catch (error) {
            this.toastr.error(error, "Failed !");
        }
    }

    async fetchDispositionData() {
        let output: any;
        try {
            output = await this.http.getD(this.constants.getcommondata + '?Name=disposition')
            if (output['status'] == "Success" && output['result']) {
                this.disposition = output['result']['data'] ? output['result']['data'] : [];
            }
        } catch (error) {
            this.toastr.error(error, "Failed !");
        }
    }

    async fetchSubDispositionData() {
        let output: any;
        try {
            output = await this.http.getD(this.constants.getcommondata + '?Name=sub_disposition')
            if (output['status'] == "Success" && output['result']) {
                this.subDisposition = output['result']['data'] ? output['result']['data'] : [];
            }
        } catch (error) {
            this.toastr.error(error, "Failed !");
        }
    }

    async fetchDispositionAgentData() {
        let output: any;
        try {
            output = await this.http.getD(this.constants.getcommondata + '?Name=disposition_agent')
            if (output['status'] == "Success" && output['result']) {
                this.disposition = output['result']['data'] ? output['result']['data'] : [];
            }
        } catch (error) {
            this.toastr.error(error, "Failed !");
        }
    }

    async fetchSubDispositionAgentData() {
        let output: any;
        try {
            output = await this.http.getD(this.constants.getcommondata + '?Name=sub_disposition_agent')
            if (output['status'] == "Success" && output['result']) {
                this.subDisposition = output['result']['data'] ? output['result']['data'] : [];
            }
        } catch (error) {
            this.toastr.error(error, "Failed !");
        }
    }

    getEmploymentStatusList() {
        this.docTypeList = [];
        let url = this.constants.getEmploymentStatusList
        try {
            this.http.postData(url, {}).then(response => {
                if (response['status'] && response['status'].match(/Success/gi) && response['result'].length > 0) {
                    let result = response['result'];
                    if (this.docTypeList.length == 0) {
                        result.forEach((item, index) => {
                            let obj = {
                                id: index,
                                itemName: item,
                                key: item,
                                value: item
                            }
                            this.docTypeList.push(obj);
                        });
                    }
                }
            })
        } catch (error) {
            this.toastr.error(error, "Failed !");
        }
        }
        getDocChangeTableData(reqBody){
            let url=this.constants.getdocChangereqdata;
            return this.http.postData(url,reqBody)  
        }
        updateDocChangeData(reqBody){
            let url=this.constants.updatedocchange
            return this.http.postData(url,reqBody)
        }

    saveData(reqObj) {
        if (reqObj) {
            return this.http
                .postData(this.constants.saveInsData, reqObj)
                .then(res => {
                    if (res['status'].match(/success/gi)) {
                        // this.refresh();
                        this.toastr.success('Details Saved');
                        return true;
                    } else {
                        this.toastr.warning('Details Not Saved');
                        return false;
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.toastr.error(err);
                });

        }
    }

    openPdf(filearr) {
        console.log('working', filearr)
        let value = filearr && filearr.filter((item) => {
            if (item['Visibility']) {
                return item
            }
        })
        value = value ? value[0] : null

        let tempDownloadObj = {}
        console.log("value", value)
        if (value && value["Url"] && value["Url"].length > 0 && value["Key"] == "Prescription") {
            // let index = value["Url"].findIndex(x=> x.match(/EMR/gi))
            // if(index !== -1){
            //     value["Url"].map((x, i)=>{
            //         this.downloadFile(x, "Prescription-"+i)
            //     })
            //     this.toastr.success("Bill Download Successfully")
            // }
            // console.log("value", value['Url'])
            this.downloadFile(value["Url"], "Prescription")
        }
        else {
            this.toastr.info("Prescription Not Found")
        }
    }

    hasOwnProperty = Object.prototype.hasOwnProperty;

    async getSignedUrl(urls) {
        let signedUrl = [];
        const reqHeader={
            appname:"MEDDASH"
        }
        let res = await this.http.postData(this.constants.getSignedUrl, { urls: urls },false,reqHeader)
        if (res && res['status'] === 'Success') {
            signedUrl = res['result']
        }
        return signedUrl
    }

    isEmpty(obj) {

        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (this.hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    }

    async downloadFile(fileURL, fileName) {
        if (fileURL) {
            var save = document.createElement("a");
            let res = await this.getSignedUrl([fileURL]);
            if (res && Array.isArray(res) && res.length > 0) {
                fileURL = res[0];
            }
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName || 'unknown';

            var evt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            save.dispatchEvent(evt);

            (window.URL || window['webkitURL']).revokeObjectURL(save.href);
        }
    }

    keyGenerator() {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        const random = (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4())
        return random;
    }

    connectWebSocket(isReconnect: boolean = false) {
        const token = localStorage.getItem('access_token');
        const randomKey = this.keyGenerator();
        const user_id = localStorage.getItem("userId")

        if (token) {
            this.wsMessages = <Subject<any>> (
                this.wsService
                    .connect(
                        `${environment.webSocketUrl}?user_id=${user_id}&product=MedOps`,
                        isReconnect
                    )
                    .map((response: MessageEvent): any => {
                        const data = JSON.parse(response.data);
                        return data;
                    })
            );
            this.subscribeWsMessages();
        }
    }

    subscribeWsMessages() {
        this.wsMessages.subscribe(
            message => {
                this.wsMessagesObservable.next(message);
            },
            error => {
                this.connectWebSocket(true);
            },
            () => {
                console.log('socket connection closed');
                console.log('Reconnecting...');
                this.connectWebSocket(true);
            }
        );
    }

    daysBetweenDates() {
        const startDate = moment('2024-10-01');
        const currentDate = moment();
        const daysDifference = startDate.diff(currentDate, 'days');
        return daysDifference+1;
      }


    async getCommonDataValues() {
        try {
            let response = await this.http.postData(
                this.constants.getCategoryList,
                {}
            );
            return response;
        } catch (error) {
            console.log("error getcategoryList>>", error);
        }
    }


   async getInstrumentDetails(reqPayload: any) {
        try {
            let response = await this.http.postData(
                this.constants.getInstrumentDetails,
                reqPayload
            );
            return response;
            
        } catch (error) {
            console.log("error getInstrumentDetails>>", error);
        }
       
      }

    async updateInstruments(reqPayload: any) {
        try {
            let response = await this.http.postData(
                this.constants.updateInstruments,
                reqPayload
            );
            return response;
        } catch (error) {
            console.log("error getcategoryList>>", error);
        }
    }

    async updateInstrumentData(reqPayload: any) {
        try {
            let response = await this.http.postData(
                this.constants.updateInstrumentData,
                reqPayload
            );
            return response;
        } catch (error) {
            console.log("error saveInstruments>>", error);
        }
    }

    async getConsumablesList (reqPayload: any) {
        try {
            let response = await this.http.postData(
                this.constants.getConsumablesList,
                reqPayload
            );
            return response;
        } catch (error) {
            console.log("error getConsumablesList>>", error);
        }
    }

    async loadEmrData(obj: any) {
        try {
            let loadEmrDataUrl = environment['docMapLocUrl'] + `emr/v1/loadEmrData?docCategory=${obj.docCategory}&doctorId=${obj.doctorId}&comData=instrument`
            let resp = await this.http.getData(loadEmrDataUrl);
            return resp;
        } catch (error) {
            console.log("error loadEmrData>>", error);
            
        }
      }

    async getOpdTrackerDataList(reqPayload = {}, queryObj :any= {}){
        try {
            let url = environment['routeUrl'] + `api/v1/getOpdTrackerData?pageNumber=${queryObj.currentPage}&pageSize=${queryObj.pageSize}`
            let response = await this.http.postData(
                url,
                reqPayload
            );
            return response;
        } catch (error) {
            console.log("error getOpdTrackerDataList>>", error);
        }

    }

}
