import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr'
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';

@Injectable()
export class HttpServiceService {

  parseAppId = {
    'X-Parse-Application-Id': environment["appId"],
    'Content-Type': 'application/json'
  }


  beforeLoginheader = {
    "Authorization": 'Basic ' + btoa('android-client:android-secret'),
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  header: any;
  loader: boolean = false;
  constructor(private http: HttpClient, public router: Router, public toastr: ToastrService, public constants: ConstantsService) { }

  /**
  *this method posts data to a server
  *@param url - the url to post data to
  *@param data - the data to send to the url
  */

  postData(url, data, formData = false,extraHeader={}) {
    if (localStorage.getItem("access_token")) {
      this.header = {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),...extraHeader
      }
    } else {
      if (url.match(/token/gi) && !url.match(/save/gi)) {
        this.header = this.beforeLoginheader
      } else {
        this.header = {
          'Content-Type': 'application/json',
          "Authorization": "bearer" + ' ' + data["accessToken"],
        }
      }
    }
    if (formData) {
      this.header['Content-Type'] = "application/x-www-form-urlencoded"
    }
    if (url.match("googleCloud/upload")) {
      this.header = {
        "Authorization": "bearer" + ' ' + localStorage.getItem("access_token"),
      }
    }
    return new Promise((resolve, reject) => {
      const httpOptions: { headers } = {
        headers: new HttpHeaders(this.header)
      };
      this.http.post(url, data, httpOptions).subscribe(
        res => {
          resolve(res)
        },
        err => {
          console.log("Error", err)
          if (err.error && err.error.error === "invalid_grant") {
            alert(err.error.error_description)
          } else if (!!err.error && !!err.error.message) {
            this.toastr.error(err.error.message)
          }
          else if (err.error && err.error.description && (err.error.description === "Registration Failed - Doc with this mobile number already exists" || err.error.description === "Doc registration failed - Account already exists for this username." || err.error.description === "Registration Failed - Doctor with this Aadhar number already exists")) {
            this.toastr.error(err.error.description)
          } else {
            this.toastr.error("Error in API")
          }

          // console.log(err)
          reject(err);
          //alert("Something Went Wrong\n Please contact to TECH Team")
        });
    });
  }

  // postDataNew1(url, data) {
  //     if (localStorage.getItem("access_token")) {
  //         this.header = {
  //             Authorization:
  //                 localStorage.getItem("token_type") +
  //                 " " +
  //                 localStorage.getItem("access_token"),
  //             "Content-Type": "application/json",
  //         };
  //     } else {
  //         if (url.match(/request-otp/gi) || url.match(/submit-otp/gi)) {
  //             this.header = this.beforeLoginheader;
  //         } else {
  //             this.header = {
  //                 Authorization:
  //                     localStorage.getItem("token_type") +
  //                     " " +
  //                     localStorage.getItem("access_token"),
  //                 "Content-Type": "application/json",
  //             };
  //         }
  //     }
  //     return new Promise((resolve, reject) => {
  //         const httpOptions: { headers } = {
  //             headers: new HttpHeaders(this.header),
  //         };
  //         this.http.post(url, data, httpOptions).subscribe(
  //             (res) => {
  //                 resolve(res);
  //             },
  //             (err) => {
  //                 console.log(err);
  //                 if (
  //                     err["error"] &&
  //                     err["error"]["error_description"] &&
  //                     (err["error"]["error_description"] ===
  //                         "Token has expired" ||
  //                         err["error"]["error_description"] ===
  //                             "Token was not recognised")
  //                 ) {
  //                     let errPromise = new Promise((resolve, reject) => {
  //                         const httpOptionsAfter: { headers } = {
  //                             headers: new HttpHeaders(
  //                                 this.beforeLoginheader
  //                             ),
  //                         };
  //                         this.http
  //                             .post(
  //                                 this.constants.refreshToken,
  //                                 {
  //                                     username:
  //                                         "bdApp_" +
  //                                         localStorage
  //                                             .getItem("userEnterMobile")
  //                                             .trim(),
  //                                     refresh_token:
  //                                         localStorage.getItem(
  //                                             "refresh_token"
  //                                         ),
  //                                 },
  //                                 httpOptionsAfter
  //                             )
  //                             .subscribe(
  //                                 (resNew) => {
  //                                     Object.entries(resNew).forEach(
  //                                         ([key, value]) =>
  //                                             localStorage.setItem(key, value)
  //                                     );
  //                                     localStorage.setItem(
  //                                         "isbddashlogin62",
  //                                         "true"
  //                                     );
  //                                     let newToken = new Promise(
  //                                         (resolve, reject) => {
  //                                             const httpOptionsNew: {
  //                                                 headers;
  //                                             } = {
  //                                                 headers: new HttpHeaders({
  //                                                     Authorization:
  //                                                         localStorage.getItem(
  //                                                             "token_type"
  //                                                         ) +
  //                                                         " " +
  //                                                         localStorage.getItem(
  //                                                             "access_token"
  //                                                         ),
  //                                                     "Content-Type":
  //                                                         "application/json",
  //                                                 }),
  //                                             };
  //                                             this.http
  //                                                 .post(
  //                                                     url,
  //                                                     data,
  //                                                     httpOptionsNew
  //                                                 )
  //                                                 .subscribe((res) => {
  //                                                     resolve(res);
  //                                                 });
  //                                         }
  //                                     );
  //                                     resolve(newToken);
  //                                 },
  //                                 (errNew) => {
  //                                     this.toastr.error(
  //                                         "SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN"
  //                                     );
  //                                     localStorage.clear();
  //                                     this.router.navigate(["/login"]);
  //                                 }
  //                             );
  //                     });
  //                     resolve(errPromise);
  //                 } else {
  //                     this.pushErrorLogToLambda(url, data, err);
  //                     reject(err);
  //                 }
  //             }
  //         );
  //     });
  // }

  postDataNew(url, data) {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("access_token")) {
        this.header = {
          "Authorization": localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
          'Content-Type': 'application/json'
        }
      }
      const httpOptions: { headers } = {
        headers: new HttpHeaders({
          "Authorization": localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
          'Content-Type': 'application/json'
        })
      };
      this.http.post(url, data, httpOptions).subscribe(
        res => {
          resolve(res);
        },
        err => {
          if (err) {
            reject(err);
          }
        });
    });
  }
  /**
   * this method returns an observeable. this method gets data from a url and returns it
   * @param url the url from which data is to be fetched
   */


  getData(url) {
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe(data => {
          resolve(data)
        },
          err => {
            reject(err)
          });
    });
  }

  putData(url, data) {
    if (localStorage.getItem("access_token")) {
      this.header = {
        Authorization:
          localStorage.getItem("token_type") +
          " " +
          localStorage.getItem("access_token"),
        "Content-Type": "application/json;",
      };
    } else {
      if (url.match(/token/gi)) {
        this.header = this.beforeLoginheader;
      } else {
        this.header = {
          Authorization: "bearer" + " " + data["accessToken"],
          "Content-Type": "application/json;",
        };
      }
    }
    return new Promise((resolve, reject) => {
      const httpOptions: { headers } = {
        headers: new HttpHeaders(this.header),
      };
      this.http.put(url, data, httpOptions).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          if (err["status"] == 401) {
            let errPromise = new Promise((resolve, reject) => {
              const httpOptions: { headers } = {
                headers: new HttpHeaders(
                  this.beforeLoginheader
                ),
              };
              this.http
                .post(
                  environment["routeUrl"] + "oauth/token",
                  "grant_type=refresh_token&refresh_token=" +
                  localStorage.getItem("refresh_token"),
                  httpOptions
                )
                .subscribe(
                  (resNew) => {
                    localStorage.clear();
                    Object.entries(resNew).forEach(
                      ([key, value]) =>
                        localStorage.setItem(key, value)
                    );
                    localStorage.setItem(
                      "isInsDashLogin91",
                      "true"
                    );
                    let newToken = new Promise(
                      (resolve, reject) => {
                        const httpOptionsNew: {
                          headers;
                        } = {
                          headers: new HttpHeaders({
                            Authorization:
                              localStorage.getItem(
                                "token_type"
                              ) +
                              " " +
                              localStorage.getItem(
                                "access_token"
                              ),
                            "Content-Type":
                              "application/json;",
                          }),
                        };
                        this.http
                          .put(
                            url,
                            data,
                            httpOptionsNew
                          )
                          .subscribe((res) => {
                            resolve(res);
                          });
                      }
                    );
                    resolve(newToken);
                  },
                  (errNew) => {
                    this.toastr.info(
                      "SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN"
                    );
                    localStorage.clear();
                    this.router.navigate(["/login"]);
                  }
                );
            });
            resolve(errPromise);
          } else {
            this.toastr.error(
              "Something Went Wrong\n Please contact to TECH Team"
            );
            reject(err);
          }
        }
      );
    });
  }


  postDataSuperAdmin(url, data) {
    const httpOptions: { headers } = {
      headers: new HttpHeaders(this.parseAppId)
    };
    return new Promise((resolve, reject) => {
      this.http.post(url, data, httpOptions).subscribe(
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        });
    });
  }


  getD(url) {
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(
        res => {
          resolve(res)
        },
        err => {

          this.toastr.error("Something Went Wrong\n Please contact to TECH Team")
          return reject(err);
        });
    });
  }
  pushErrorLogToLambda(url, reqBody, err) {
    let data = {
      message: JSON.stringify(err),
      errortype: "product list error",
      endpoint: url,
      product: "insurance_dashboard-" + window.location.href,
      requestBody: reqBody,
    };
    if (!environment["production"]) {
      return;
    }
    // this.http
    //     .post(
    //         "https://edzs926ktc.execute-api.ap-south-1.amazonaws.com/prod/savelog",
    //         data
    //     )
    //     .subscribe(
    //         (res) => {
    //             console.log(res);
    //         },
    //         (err) => {
    //             if (err) {
    //                 console.log(err);
    //             }
    //         }
    //     );
  }

  postFormData(url, data) {
    const httpOptionsNew: {
      headers;
    } = {
      headers: new HttpHeaders({
        Authorization:
          localStorage.getItem(
            'token_type'
          ) +
          ' ' +
          localStorage.getItem(
            'access_token'
          ),
      }),
    };
    return new Promise((resolve, reject) => {
      this.http.post(url, data, httpOptionsNew).subscribe(
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        });
    });
  }

  deleteData(url) {
    if (localStorage.getItem("access_token")) {
      this.header = {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
      }
    }
    return new Promise((resolve, reject) => {
      const httpOptions: { headers } = {
        headers: new HttpHeaders(this.header)
      };
      this.http.delete(url, httpOptions).subscribe(
        res => {
          resolve(res)
        },
        err => {
          console.log("Error", err)
          this.toastr.error("Error in API")
          reject(err);
        });
    });
  }

  post(url, reqObject): Observable<any> {
    return this.http.post<any>(url, reqObject);
  }

  get(url) {
    return this.http.get<any>(url);
  }
}
