import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contract-common-dialog',
  templateUrl: './contract-common-dialog.component.html',
  styleUrls: ['./contract-common-dialog.component.scss']
})
export class ContractCommonDialogComponent implements OnInit {

  constructor(
    public dialogRef:MatDialogRef<ContractCommonDialogComponent>,
  ) { }

  ngOnInit() {
  }

}
