import { Component, OnInit, Input, ViewChild, HostListener, Inject, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpServiceService } from '../shared/services/http-service.service';
import { ConstantsService } from '../shared/services/constants.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import html2pdf from 'html2pdf.js';
import CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { CommonService } from '../shared/services/common.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ContractCommonDialogComponent } from './dialogs/contract-common-dialog/contract-common-dialog.component';


@Component({
  selector: 'app-hr-contract',
  templateUrl: './hr-contract.component.html',
  styleUrls: ['./hr-contract.component.scss']
})
export class HrContractComponent implements OnInit {
  subCategory: string = '';
  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event) {
    this.isDrawing = false;
  }

  onTouchStart(event) {
    this.isDrawing = true;
    const coords = this.getRelativeCoords(event);
    this.context.moveTo(coords.x, coords.y);
  }

  onTouchMove(event) {
    event.preventDefault();
    if (this.isDrawing) {
      const coords = this.getRelativeCoords(event);
      this.written = true;
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }
  private getRelativeCoords(event) {
    const bounds = this.sigPadElement.getBoundingClientRect();
    let x, y;

    if (event.touches && event.touches.length > 0) {
      x = event.touches[0].clientX - bounds.left;
      y = event.touches[0].clientY - bounds.top;
    } else {
      x = event.clientX - bounds.left;
      y = event.clientY - bounds.top;
    }

    return { x, y };
  }

  otpForm: FormGroup = new FormGroup({});
  pristynContractDate;
  pristynDocName;
  pristynDoctorAddress
  pristynPanCardNumber
  contractDetails;
  isOnlyDocSchedule: boolean = false;
  isChecked: boolean = false;
  @ViewChild('sigPad') sigPad;
  sigPadElement;
  context;
  isDrawing = false;
  img;
  digitalSign: boolean = true;
  id;
  url;
  isOTPVarify: boolean = true;
  urlId;
  anexureDetails;
  loading: boolean = false;
  anexureDetails1
  anexureDetails2;
  imageurls: any;;
  anexureDetails3;
  anexureDetails4
  anexureDetails5;;
  anexureDetails6;
  anexureDetails7;
  written = false;
  saved = false;
  content;
  isDigitalSignature;
  documentStatus;
  isloader: boolean = true;
  isDownloading: boolean = false;
  x = false;
  mobilenumber;

  @ViewChild('contentContainer') contentContainer: ElementRef;

  constructor(
    public toastr: ToastrService,
    public http: HttpServiceService,
    public constants: ConstantsService,
    public formBuilder: FormBuilder,
    public fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    public router: Router,
    private datePipe: DatePipe,
    public common: CommonService,
    private https: HttpClient,
    private cdr: ChangeDetectorRef,
    public dialog:MatDialog,
    @Inject(DOCUMENT) private document: any
  ) {
  }


  ngOnInit() {
    this.createOTPForm();
    const urlSeg = window.location.href.split('/');
    this.urlId = urlSeg[urlSeg.length - 1];
    this.getLinkForContractData();

    // this.getAnexureDetails()
    if (this.contentContainer) {
      this.content = this.contentContainer.nativeElement.innerHTML;
    }
    // this.downloadAndDisplayImages()
  }

  ngAfterViewInit() {
    // document.location.href = 'hr/' +this.id
    if (this.sigPad) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {

    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    console.log(this.isDrawing)
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.written = true;
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();

    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
    this.written = false;
    // this.save()
  }

  save() {
    this.img = this.sigPadElement.toDataURL("image/png");
    this.digitalSign = false;
    this.saved = true;
  }

  convertToPDF(): void {
    const element = document.getElementById('pdfContent');
    const opt = {
      margin: 0,
      filename: 'pristyncare.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    this.isDownloading = true;

    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const pageCount = pdf.internal.getNumberOfPages();
        if (pageCount > 1) {
          pdf.deletePage(pageCount);
        }
        this.isDownloading = false;
        this.cdr.detectChanges();
      })
      .save()
  }

  createOTPForm() {
    this.isOnlyDocSchedule = true
    this.otpForm = this.fb.group({
      otp: [{ value: '', disabled: !this.isOnlyDocSchedule }, Validators.required],
      mobile: [{ value: '', disabled: !this.isOnlyDocSchedule }, [Validators.required, Validators.pattern("[0-9][0-9]{9}"), Validators.minLength(10), Validators.maxLength(10)]],
    })
  }

  async sendHTML() {
    let url = this.constants.sendHTMLContent;
    let reqObj = {
      "contractId": this.urlId,
      "html": this.content,
      "link": this.contractDetails.link,
      'signatureURL': this.img,
      "hash": CryptoJS.SHA256(this.mobilenumber).toString(CryptoJS.enc.Hex),
    }

    try {
      let response: any = await this.http.postData(url, JSON.stringify(reqObj));
      if (response['status'] === "Success") {
        this.toastr.success(response['description'], "Success !")
        this.convertToPDF();
        this.activeModal.close();
      } else {
        this.toastr.error(response['description']);
      }
    } catch (e) {

    }
  }

  // clickedbutton(){
  //   this.imageurls=[
  //     this.anexureDetails1,
  //     this.anexureDetails2,

  //   ];
  //   this.downloadAndDisplayImages()

  // }


  downloadContract() {
    this.convertToPDF();
  }

  async varifyOTP(form) {
    if (!this.otpForm.value.mobile) {
      this.toastr.error("Phone number is required!")
      return;
    }

    if (!this.otpForm.value.otp) {
      this.toastr.error("OTP is required!")
      return;
    }
    this.mobilenumber = this.otpForm.value.mobile.toString()
    this.mobilenumber = "91" + this.mobilenumber
    let reqObj = {
      "email": this.contractDetails.email,
      "mobile": this.mobilenumber,
      "contractId": this.urlId,
      "otp": this.otpForm.value.otp,
    }
    let url = this.constants.verifyOTP;
    try {
      let response: any = await this.http.postData(url, JSON.stringify(reqObj));
      if (response['status'] === "Success") {
        this.getLinkForContractData();
        this.isOTPVarify = false;
        this.contractDetails.docSignDate = new Date().getTime();
        this.documentStatus= 'VERIFIED';
        this.sendHTML();
        this.toastr.success(response['description'], "Success !")
        this.activeModal.close();
      } else {
        this.toastr.error(response['description'])
      }
    } catch (e) {
      console.log("error", e)
    }
  }

  yourfunc(e) {
    if (e.target.checked) {
      this.isChecked = e.target.checked;
      console.log(this.isChecked)
    } else {
      this.isChecked = false
      console.log(this.isChecked)
    }
  }

  get f() { return this.otpForm.controls; }

  async getLinkForContractData() {
    let url = this.constants.getLinkForContractData + this.urlId;
    try {
      let response: any = await this.http.getD(url);
      if (response['status'] === "Success") {
        this.contractDetails = response.result;
        this.pristynContractDate = this.contractDetails.contractDate
        const contractDateLimit = new Date(this.pristynContractDate);    
        contractDateLimit.setHours(21, 0, 0, 0);
        const contractmillisec = contractDateLimit.getTime();
        if(new Date().getTime()>contractmillisec){
          const dialog = this.dialog.open(ContractCommonDialogComponent,{
            width:'30vw',
            autoFocus:false,
            disableClose:true
          })
          dialog.afterClosed().subscribe(result=>{

          })
        }
        this.pristynDocName = this.contractDetails.docName
        this.pristynPanCardNumber = this.contractDetails.panCardNumber
        this.pristynDoctorAddress = this.contractDetails.doctorAddress
        this.isDigitalSignature = this.contractDetails.signatureURL
        this.documentStatus = this.contractDetails.status;
        this.subCategory = this.contractDetails.subCategory;
        this.isloader = false;
        this.getAnexureDetails()
        // doctorData = response['result'][0];
        // this.data['createdby_name'] = response['result'][0]['createdby_name']
        // console.log(doctorData)
        // if (!doctorData.isDraft) {
        //   this.onlyPublish = true;
        // }
        // this.prifilledForm(doctorData);
        // this.loading = false;
      } else {
        this.toastr.error(response['description'])
        // this.loading = false;

      }
    } catch (e) {
      // this.loading = false;

    }
  }
  async getAnexureDetails() {
    if (!this.contractDetails || !this.contractDetails.category || !this.contractDetails.subCategory) {
      this.toastr.error('Missing contract details or properties. Cannot fetch annexure data.');
      return;
    }

    let category = [this.contractDetails.category[0]];
    let subcategory = this.contractDetails.subCategory;

    let url = `${this.constants.getAnnexureDocData}getAnnexureDoc?category=${category}&subCategory=${subcategory}`;
    this.loading = true;

    try {
      let response: any = await this.http.getD(url);
      this.loading = false;

      if (response['status'] === 'Success' && response.result && response.result.imageUrls &&
        response.result.imageUrls.length > 0
      ) {
        this.anexureDetails = response.result && response.result.imageUrls ? response.result.imageUrls : [];

        for (let i = 0; i < 7; i++) {
          if (this.anexureDetails[i]) {
            if (this.anexureDetails[i].includes('annexure_a.1.png')) {
              this.anexureDetails1 = this.anexureDetails[i];
            } else if (this.anexureDetails[i].includes('annexure_a.2.png')) {
              this.anexureDetails2 = this.anexureDetails[i];
            } else if (this.anexureDetails[i].includes('annexure_b.1.png')) {
              this.anexureDetails3 = this.anexureDetails[i];
            } else if (this.anexureDetails[i].includes('annexure_b.2.png')) {
              this.anexureDetails4 = this.anexureDetails[i];
            } else if (this.anexureDetails[i].includes('annexure_b.3.png')) {
              this.anexureDetails5 = this.anexureDetails[i];
            } else if (this.anexureDetails[i].includes('annexure_b.4.png')) {
              this.anexureDetails6 = this.anexureDetails[i];
            } else if (this.anexureDetails[i].includes('annexure_b.5.png')) {
              this.anexureDetails7 = this.anexureDetails[i];
            }
          }
        }

        this.imageurls = [
          this.anexureDetails1,
          this.anexureDetails2,
          this.anexureDetails3,
          this.anexureDetails4,
          this.anexureDetails5,
          this.anexureDetails6,
          this.anexureDetails7,
        ];

        this.downloadAndDisplayImages();
      } else {
        this.toastr.error(response['description']);
      }
    } catch (e) {
      console.error('Error fetching annexure details:', e);
      this.loading = false;
    }
  }

  tempImages: string[] = [];
  async downloadAndDisplayImages(): Promise<void> {
    for (const url of this.imageurls) {
      try {
        const imageData = await this.downloadImage(url);
        const base64ImageData = await this.saveImageLocally(imageData);
        this.tempImages.push(base64ImageData);
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    }
  }

  async downloadImage(url: string): Promise<Blob> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    return await response.blob();
  }

  saveImageLocally(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
