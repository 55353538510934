// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// +++++++++++For Staging+++++++++++++
// export const environment = {
//   production: false,
//   newRoute: 'http://13.126.6.117/',
//   appId: 'localparseApp123',
//   parseUrl: 'http://13.233.153.146/parse',
//   docServiceUrl: 'http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/bd-dash',
//   routeUrl: 'https://java-staging.pristoncare.com/',
//   docMapLocUrl: 'https://java-staging.pristoncare.com/',
//   emrUrl: 'http://emrtestserver-862306767.ap-south-1.elb.amazonaws.com/',
//   googleClientId: '173529304992-mdptju7mpgevb8p547u8njn100pa4bom.apps.googleusercontent.com',
//   superAdminUrl: "http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/docker-parse",
//   sentryEnabled: false,
//   dsnSentry: 'https://284d4a75a40b404ab304b94c0ca4b3bc@o423730.ingest.sentry.io/6711380',
//   baseUrl: 'https://java-staging.pristoncare.com/',
//   googleMapsAPI: "AIzaSyAY64cejKVuNd0wOS_mIqf6izuqamNYYqQ",
//   bdDashUrl: "http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/bd-dash/bd-dashboard/",
//   clientId: "173529304992-mdptju7mpgevb8p547u8njn100pa4bom.apps.googleusercontent.com",
//   webSocketUrl: "wss://gg0bq10ij1.execute-api.ap-south-1.amazonaws.com/staging",
//   javaApiForTrainingModule: "https://java-staging.pristoncare.com/",
//   escalatingTicket: 'https://ticketing.pristyntech.com/admin/tables/?authToken=access_token',
//   ticketrouteUrl: 'http://pristyn-java-staging-1025160438.ap-south-1.elb.amazonaws.com/escalationService/',
//   nodebaseUrl: 'https://node-staging.pristoncare.com/bch/bd-dash/md-dashboard/',
//   nodeBdUrl:'https://node-staging.pristoncare.com/bch/bd-dash/bd-dashboard/',
//   firebaseConfig: {
//     apiKey: 'AIzaSyAilWRA5jQoSbHKnhriOHM05CyiHqslN6g',
//     authDomain: 'hospital-app-6a354.firebaseapp.com',
//     projectId: 'hospital-app-6a354',
//     storageBucket: 'hospital-app-6a354.appspot.com',
//     messagingSenderId: '151644699555',
//     appId: '1:151644699555:web:1966c4cd8159f3fb09b816',
//   },
// };

export const environment = {
  production: true,
  mode: "production",
  staging: false,
  routeUrl: 'https://pristyntech.com/java/',
  docMapLocUrl: 'https://pristyntech.com/',
  newRoute: 'https://pristyntech.com/report',
  appId: 'skdud8dcswb529335f7ekdhdh23c6ca132064ba6867dhfadlsl6dc471b08299ddiw0nvsdprtyhagmsn',
  parseUrl: 'http://prodloadbalancer-499401724.ap-south-1.elb.amazonaws.com/parse',
  emrUrl: 'http://emrprod-1332067984.ap-south-1.elb.amazonaws.com/',
  docServiceUrl: 'https://pristyntech.com/doc',
  clientId: '173529304992-ut4571ogbg9p243ddjqb8d11fa080vhf.apps.googleusercontent.com',
  superAdminUrl: "https://details.pristyntech.com",
  sentryEnabled: false,
  dsnSentry: 'https://284d4a75a40b404ab304b94c0ca4b3bc@o423730.ingest.sentry.io/6711380',
  baseUrl: 'https://pristyntech.com/java/',
  googleMapsAPI: "AIzaSyAuLw0ylArfJgFdQ0NHrjjIbg4btK5DM-0",
  bdDashUrl: "https://pristyntech.com/doc/bd-dashboard/",
  webSocketUrl: "wss://4y19z1rdrk.execute-api.ap-south-1.amazonaws.com/production",
  javaApiForTrainingModule: "https://pristyntech.com/",
  escalatingTicket: 'https://ticketing.pristyntech.com/admin/tables/?authToken=access_token',
  ticketrouteUrl: 'https://pristyntech.com/escalationService/',
  //prod node url
  nodebaseUrl: 'https://pristyntech.com/doc/md-dashboard/',
  nodeBdUrl:'https://pristyntech.com/doc/bd-dashboard/',
  firebaseConfig: {
    apiKey: 'AIzaSyAilWRA5jQoSbHKnhriOHM05CyiHqslN6g',
    authDomain: 'hospital-app-6a354.firebaseapp.com',
    projectId: 'hospital-app-6a354',
    storageBucket: 'hospital-app-6a354.appspot.com',
    messagingSenderId: '151644699555',
    appId: '1:151644699555:web:1966c4cd8159f3fb09b816',
  },
};

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.

//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
