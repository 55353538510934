import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
import { HrContractComponent } from './hr-contract/hr-contract.component';

const routes: Routes = [
    { path: '', loadChildren: './layout/layout.module#LayoutModule',canActivate: [AuthGuard] },
    { path: 'hr-contract/hr/:id', loadChildren: './hr-contract/hr-contract.module#HrContractModule'},
    { path: '**', redirectTo: 'login' },
    { path: 'login', loadChildren: './login-v2/login-v2.module#LoginV2Module'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
