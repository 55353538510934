import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { HttpServiceService } from './shared/services/http-service.service';
import { ConstantsService } from './shared/services/constants.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonService } from './shared/services/common.service';
import { ExcelService } from './shared/services/excel.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { UtilService } from "./shared/services/util.service";
import { IframePopup } from './layout/components/iframePopup.component';
import { ClickToCallComponent } from './layout/components/clickToCall/clickToCall.component'
import { AgmCoreModule } from '@agm/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SignInResolver } from './shared/resolvers/login.resolver';
import { JwtAuthInterceptor } from './shared/interceptors/jwt-auth.interceptor';
import { CallMaskComponent } from './shared/dialogs/call-mask/call-mask.component';
import { ShowNumberComponent } from './shared/dialogs/show-number/show-number.component';
import { OtpTextBoxDirective } from './shared/directives/otp-text-box.directive';
import { MatIconModule, MatOptionModule, MatSelectModule, MatDialogModule, MatButtonModule, MatInputModule, MatSnackBarModule, MatProgressSpinnerModule, MatCardModule } from '@angular/material';
import { ErrorHandler, Injectable, NgModule } from "@angular/core";
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/browser";
import { HrContractModule } from './hr-contract/hr-contract.module';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/messaging';


if (environment['sentryEnabled']) {
    Sentry.init({
        dsn: environment['dsnSentry'],
        environment: environment['production'] ? 'production' : 'staging'
    } as any);
}
@Injectable()
export class SentryErrorHandler implements ErrorHandler {


    handleError(error) {
        if (environment['sentryEnabled']) {
            // const eventId = Sentry.captureException(error.originalError || error);
        }
    }
}
@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 1000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        NgbModule,
        Ng2OrderModule,
        NgxPaginationModule,
        AngularMultiSelectModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatSnackBarModule,
        MatCardModule,
        MatProgressSpinnerModule,
        HrContractModule,
        AgmCoreModule.forRoot({
            apiKey: environment['googleMapsAPI']
        })
    ],
    declarations: [AppComponent, IframePopup, ClickToCallComponent, CallMaskComponent, ShowNumberComponent, OtpTextBoxDirective],
    providers: [AuthGuard, HttpServiceService, UtilService, AngularFireModule, AngularFireMessaging,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtAuthInterceptor,
            multi: true,
        },
        ConstantsService, CommonService, ExcelService, DatePipe, SignInResolver, TitleCasePipe, SafeUrlPipe],
    bootstrap: [AppComponent],
    entryComponents: [IframePopup, ClickToCallComponent, CallMaskComponent, ShowNumberComponent],
    exports: [MatProgressBarModule]
})
export class AppModule { }
