import { Injectable } from '@angular/core';
import { env } from 'process';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConstantsService {
  login = environment["routeUrl"] + "oauth/token";
  route = environment["routeUrl"] + "api/md/";
  bdNode=environment['nodeBdUrl']
  superAdminUrl = environment["superAdminUrl"] + "/parse/";
  signedRoute = environment["routeUrl"] + "api/";
  emrUrl = environment["emrUrl"] + "doc/EMR/";
  dummyRoute = "http://localhost:3000/"
  docServiceUrl = environment["docServiceUrl"]
  bdDashboardRoute = this.docServiceUrl + "/bd-dashboard/"
  getData = this.bdDashboardRoute + "getBDDashDoctorData";
  appointment = this.route + "insIpd";
  saveInsData = this.route + "saveInsuranceData";
  saveImage = environment["routeUrl"] + "api/saveImage";
  sendEmail = this.route + "sendEmail";
  sendEmailDirect = this.route + "sendEmailDirect"
  logout = this.route + "logout";
  clickToCall = this.route + "ClickTOCall";
  getSRNumber = "http://13.126.149.224/doc/bd-dashboard/forwarding/" + "getSRNumber";
  //IPD Tracker
  ipdLiveList = environment["docMapLocUrl"] + "docApp/ipdLive/list";
  getCommonData = environment["docMapLocUrl"] + "docApp/ipdLive/api/getCommonData";
  getOnboardingModuleData = this.route + "getOnboardingModuleData"
  getPrescriptionApprovalModuleData = this.route + "getPrescriptionApprovalModuleData"
  getCategoryAndTreatments = this.route + "getCategoryAndTreatments"
  getCityList = this.route + "getCityList"
  getEmploymentStatusList = this.route + "getUniqueEmploymentStatus"
  getLeavesInfo = this.route + "getLeavesInfo"
  getDocList = this.route + "getDocList"
  getDoctorList = this.signedRoute + "v1/getDoctorList/";
  updateSurgeon = this.signedRoute + "v1/updateSurgeon/";
  sendMsgToPatient = this.signedRoute + "sendMessageToPatient/";
  updateLeave = this.route + "updateLeave"
  getDoctorPayoutDocData = this.route + "getDoctorPayoutDocData";
  updateAppointmentRiskType = this.route + "updateAppointmentRiskType"
  addRemarks = this.route + "addRemarks"
  getcommondata = environment["routeUrl"] + "api/getcommondata"
  getCityRegionMapper = this.route + "getCityRegionMapper"
  addDoctor = this.route + "addDoctor"
  getDocDetails = this.route + "getDocDetails"
  updateDocDetails = this.route + "updateDocDetails"
  getAllAssociatedHospitals = this.route + "getAllAssociatedHospitals"
  updateDocStatus = this.route + "updateDocStatus";
  getVCModuleData = this.route + "getVCModuleData";
  getVcFilterData = this.route + "getVCModuleFilterData";
  getFilterData = this.route + "getFilterData";
  getVCModalData = this.route + "VCPopupDropDownData";
  updateAppointmentDetails = this.route + "updateAppointmentDetails";
  docVisibility = this.route + "docVisibility";
  getDocLocationsByDate = this.route + "getDocLocationsByDate";
  docPaitentExcel = this.route + "docPaitentExcel"
  downloadVCModuleData = this.route + "downloadVCModuleData"
  signedUrl = 'https://pristyntech.com/java/api/getSignedUrl';
  doctorList = this.superAdminUrl + "classes/DiseaseSurgeryList";
  leaveDetails = this.superAdminUrl + "classes/LeaveDetails";
  superAdminGetBookSlots = this.superAdminUrl + "functions/superAdminGetBookSlots";
  getSurgeryRiskData = this.route + "surgeryRiskController/getTableData";
  saveOtherNumber = this.route + "surgeryRiskController/saveOtherNumber";
  getMdQcTableData = this.route + "surgeryRiskController/getMdQcTableData";
  getRiskCategroyData = this.route + "surgeryRiskController/getRiskCategoryData";
  editRiskCategoryData = this.route + "surgeryRiskController/postRiskCategoryData";
  getSignedUrl = this.signedRoute + 'getSignedUrl';
  getPacData = this.route + "surgeryRiskController/getPACFormData"
  getCliNumberList = this.docServiceUrl + '/bd-dashboard/getSRNumber'
  clickToCall2 = this.docServiceUrl + '/bd-dashboard/ClickTOCall'
  postPac = this.route + "surgeryRiskController/postPACFormData"
  getIPDReportData = environment["newRoute"] + 'api/ipd/report'
  saveContractData = environment["routeUrl"] + 'api/contract/saveContractData'
  getAllContractData = environment["routeUrl"] + 'api/contract/getAllContractData'
  getPanCardDetails = environment["routeUrl"] + 'api/contract/getByPanCard?panCard='
  getLinkForContractData = environment["routeUrl"] + 'exposed/contract/getLinkForContractData?contractId='
  getAnnexureDocData = environment["routeUrl"] + 'exposed/contract/'
  sendHTMLContent = environment["routeUrl"] + 'exposed/contract/saveHtml'

  getOTP = environment['routeUrl'] + 'otp/ocr/getotp';
  verifyOTP = environment['routeUrl'] + 'otp/ocr/verifyotp';
  getHishRiskSurgeryReportData = environment['newRoute'] + '/api/surgery'
  getHighRiskPtList = this.route + "surgeryRiskController/getMismatchedData";
  getDoctorPerformanceReport = environment['newRoute'] + '/api/doctors/performance'
  getDirectReportType = environment['newRoute'] + '/api/ipd/directIpd'
  getActiveDoctorReportType = environment['newRoute'] + '/api/doctors/active'
  postPatientSurgeryRiskStatus = this.route + 'surgeryRiskController/saveSrcStatus'
  getHospitalListJava = environment["routeUrl"] + 'exposed/getHospitalList'
  getPatientDetails = this.route + 'getPatientDetails'
  sendEmailMedicalCertificate = this.route + 'sendEmailMedicalCertificate'
  downloadMedicalCertificate = this.route + 'downloadPaitentPrescription'
  updateMedicalCertificate = this.route + 'updateMedicalCertificate'
  multipleImage = this.signedRoute + 'v2/save/multipleImage'
  doctorMaster = this.signedRoute + "v1/doctorMaster";
  googleCloudUpload = this.signedRoute + "v1/googleCloud/upload";
  doctorMasterListUpdate = this.signedRoute + "v1/doctorMasterListViewData";
  getDoctorMasterList = this.signedRoute + "v1/doctorMasterFilterData";
  deleteDoc = this.signedRoute + "v1/doctorMaster";
  surgeryRiskCall = this.emrUrl + 'surgeryRiskCall';
  srNumber = environment['routeUrl'] + "api/getSRNumber";
  getDoctorChangeList = environment['routeUrl'] + "api/md/getDoctorChangeRequest";
  sendMailForDoctorChange = environment['routeUrl'] + "api/md/sendMailForDoctorChange";
  clickToCallLeads = environment['routeUrl'] + "api/v3/clickToCallLeads"
  gmbUrl = environment['routeUrl'] + "api/mybusiness/createLocations"
  gmbDeleteUrl = environment['routeUrl'] + "api/mybusiness/deleteLocations"
  gmbGetDescription = environment['routeUrl'] + "api/mybusiness/gmbDescription"
  sendOtpMask = environment['routeUrl'] + "api/sendLeadUnmaskOtp";
  verifyOtp = environment['routeUrl'] + "api/verifyLeadUnmaskOtp";
  pacVerifyOtp = environment['routeUrl'] + "otp/pac/verifyotp"
  pacSendOtp = environment['routeUrl'] + "otp/pac/getotp"
  tableCountData = this.route + 'surgeryRiskController/getTableDataCount';
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  getHospitalCityBasis = environment["routeUrl"] + "api/v1/getHospitalOnCityBasis";
  getRegionOnCityBasis = environment["routeUrl"] + "api/v1/getRegionOnCityBasis";
  getDoctorLocation = environment["docMapLocUrl"] + "docApp/getDoctorLocationMapping"
  addDoctorLeads = this.route + "addDoctorLeads";
  getDoctorCallLogs = this.route + "getDoctorCallLogs";
  doctorListForDocHiring = this.signedRoute + "md/getDocLeadsList"
  getClinicIds = environment['routeUrl'] + "api/v1/getClinicId?city="
  updateDoctorForDocHiring = this.signedRoute + "md/updateDoctorLeads";
  doctorRemarksForDocHiring = this.signedRoute + "md/updateDoctorLeadsRemarks";
  uploadDoctorCSVForDocHiring = this.signedRoute + "md/uploadDoctorLeadsCSVFile";
  downloadDoctorCSVForDocHiring = this.signedRoute + "md/downloadDoctorLeadsCSVFile";
  callReminderForDocHiring = this.signedRoute + "md/saveLeadCallReminders?docId=";
  getAddressFromLAtLong = environment['docMapLocUrl'] + "java/exposed/v9/getLocFromCoordinates";
  surgeryRiskremarks = this.route + "surgeryRiskController/getAllQcRemarksData";
  updateQcRemarksData = this.route + "surgeryRiskController/updateQcRemarksData";
  saveCallStatus = this.route + 'surgeryRiskController/saveCallStatus';
  hospitalBlockingRequests = this.route + 'v1/hospitalBlockingRequests'
  listHospitalStatus = this.route + 'listHospitalStatus';
  doctorRemarksList = this.route + 'doctorRemarksList?docId={docId}';

  //Final Assessment Training Module
  javaApiForTrainingModule = environment["javaApiForTrainingModule"] + "leadsAlternative/api/"
  getFinalAssessment = this.javaApiForTrainingModule + "getModuleData";
  saveAssessmentQuestions = this.javaApiForTrainingModule + "increaseBdTrainingProgress";
  getModuleData = this.javaApiForTrainingModule + "getModuleData";
  getTrainingModules = this.javaApiForTrainingModule + "getTrainingModules";
  increaseBdTrainingProgress = this.javaApiForTrainingModule + "increaseBdTrainingProgress";
  retakeTraining = this.javaApiForTrainingModule + "retakeTraining";
  getMcqReport = this.javaApiForTrainingModule + "getMcqReport";

  getScript = this.docServiceUrl + '/bd-dashboard/agent-mock-test?category={category}&BDObjectID={BDObjectID}'
  getFaqData = this.docServiceUrl + '/bd-dashboard/agent-mock-faq?category={category}&BDObjectID={BDObjectID}'
  uploadSample = this.docServiceUrl + '/bd-dashboard/simpleUpload'
  finalSaveAudio = this.docServiceUrl + '/bd-dashboard/process-mock-test';
  getDocListWithInactive = this.bdNode + "getAllDoctorList"
  pristynLiteDoctorFilterDataList = this.signedRoute + "v1/pristynLiteDoctorFilterData";
  savePristynLiteDoctors = this.signedRoute + "v1/savePristynLiteDoctors";
  updatePristynLiteDoctor = this.signedRoute + "v1/updatePristynLiteDoctor";
  getPristynLite = this.signedRoute + "v1/getPristynLite";

  //  Planned vs Actual Schedule

  getDoctorAppointmentList = this.signedRoute + 'md/getDoctorList';
  getDoctorLeavesInfo = this.signedRoute + 'md/getDoctorLeavesInfo';
  getDoctorAppointmentData = this.signedRoute + 'md/getDoctorAppointmentData';
  doctorAvailabilityforMD = this.bdDashboardRoute + 'doctorAvailabilityforMD';

  //fetchTicketCount

  getTicketCount = environment['ticketrouteUrl'] + 'api/v1/tickets/count'

  //saveFCMtoken
  saveFCMTocken = environment['ticketrouteUrl'] + 'api/saveFcmToken';
  getdocChangereqdata = environment['nodebaseUrl'] + 'v1/getDocReqTableData';
  updatedocchange = environment['nodebaseUrl'] + 'updateDocRequestById';

  //getClinicData

  getClinicData=environment['routeUrl'] + "api/v1/getClinicDetails?clinicId="
  updateDocName=environment['routeUrl']+'api/v1/updateDocName'

  // seo content
  getSeoContent = environment['routeUrl'] + "api/getSeoContent"
  saveSeoContent = environment['routeUrl'] + "api/saveSeoContent"
  uploadImage = environment['routeUrl'] + "api/googleCloud/upload"

  // instruments api
  updateInstruments = environment['nodeBdUrl'] + "update-instruments"
  updateInstrumentData = environment['nodeBdUrl'] + "update-instruments-data"
  getInstrumentDetails =  environment['nodeBdUrl'] + "get-bd-instruments"
  getCategoryList = environment['routeUrl'] + "central-dashboard-routing/getCommonDataValues"
  getConsumablesList = environment['docMapLocUrl'] + "docApp/api/v2/getConsumablesList"
  getOpdTrackerData = environment['routeUrl'] + "api/v1/getOpdTrackerData"

  constructor() {
  }

}
